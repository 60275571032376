import React from "react";
import { graphql } from "gatsby";
import { Container, Grid, Button } from "@material-ui/core";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Twocol from "../components/strapi/structure/TwoCol";
import Outrolocation from "../components/strapi/structure/OutroLocation";
import TestimonialSlider from "../components/testimonials/TestimonialSlider";

export default function Promotions({ data }) {
  return (
    <Layout>
      <SEO title="Promotions" />
      {data.allStrapiPromotions.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.promotions_mh.mh_title}
            mhsubtitle={node.promotions_mh.mh_subtitle}
            mhbackground={node.promotions_mh.mh_bg.publicURL}
          />
          <Twocol
            title={node.promotions_txtimg.txtimg_title}
            description={node.promotions_txtimg.txtimg_description}
            blueTitle={node.promotions_txtimg.txtimg_bluetitle}
            pdf={
              <a href={node.promotional_pdf.publicURL} target="_blank">
                <Button variant="contained" color="primary">
                  <span className="text-white">
                    {node.promotions_txtimg.txtimg_bluetitle}
                  </span>
                </Button>
              </a>
            }
            bgColor={node.promotions_txtimg.txtimg_bgcolor}
            order={node.promotions_txtimg.txtimg_order}
            rowimg={node.promotions_txtimg.txtimg_img.publicURL}
          />
          {/* 2 */}
          {!!node.promotions_txtimg2 && (
            <Twocol
              title={node.promotions_txtimg2?.txtimg_title}
              description={node.promotions_txtimg2?.txtimg_description}
              blueTitle={node.promotions_txtimg2?.txtimg_bluetitle}
              pdf={
                <a href={node.promotional_pdf2?.publicURL} target="_blank">
                  <Button variant="contained" color="primary">
                    <span className="text-white">
                      {node.promotions_txtimg2?.txtimg_bluetitle}
                    </span>
                  </Button>
                </a>
              }
              bgColor={node.promotions_txtimg2?.txtimg_bgcolor}
              order={node.promotions_txtimg2?.txtimg_order}
              rowimg={node.promotions_txtimg2?.txtimg_img.publicURL}
            />
          )}

          <Outrolocation
            outro={node.promotions_location.location_description}
          />
          <TestimonialSlider />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Promotions {
    allStrapiPromotions {
      edges {
        node {
          id # id of the node
          promotions_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          promotions_txtimg {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          promotional_pdf {
            publicURL
          }
          #  promotions_txtimg 2
          # promotions_txtimg2 {
          #   id
          #   txtimg_title
          #   txtimg_description
          #   txtimg_bluelink
          #   txtimg_bluetitle
          #   txtimg_order
          #   txtimg_img {
          #     publicURL
          #   }
          # }
          # promotional_pdf2 {
          #   publicURL
          # }

          promotions_location {
            id
            location_description
          }
        }
      }
    }
  }
`;
